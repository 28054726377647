import { useState, useRef } from "react";
import "../style/TradingViewWidget.css"; // Připojíme externí CSS

const TradingViewWidget = () => {
  const [symbol, setSymbol] = useState("BTCUSDT.P");
  const iframeRef = useRef(null);

  const handleSymbolChange = (event) => {
    setSymbol(event.target.value);
  };

  const handleFullscreen = () => {
    if (iframeRef.current) {
      if (iframeRef.current.requestFullscreen) {
        iframeRef.current.requestFullscreen();
      } else if (iframeRef.current.mozRequestFullScreen) { // Firefox
        iframeRef.current.mozRequestFullScreen();
      } else if (iframeRef.current.webkitRequestFullscreen) { // Chrome, Safari and Opera
        iframeRef.current.webkitRequestFullscreen();
      } else if (iframeRef.current.msRequestFullscreen) { // IE/Edge
        iframeRef.current.msRequestFullscreen();
      }
    }
  };

  return (
    <section className="tradingview">
      <div className="select-container">
        <label htmlFor="coin-select" className="select-label">Pick a coin:</label>
        <div className="custom-select-wrapper">
          <select
            id="coin-select"
            className="inputin"
            onChange={handleSymbolChange}
            value={symbol}
          >
           <option value="MANAUSDT.P">Mana (MANA)</option>
            <option value="STXUSDT.P">Stacks (STX)</option>
            <option value="ARPAUSDT.P">ARPA chain (ARPA)</option>
            <option value="ARBUSDT.P">Arbitrum (ARB)</option>
            <option value="OPUSDT.P">Optimism (OP)</option>
            <option value="SOLUSDT.P">Solana (SOL)</option>
            <option value="XRPUSDT.P">XRP (XRP)</option>
            <option value="BLURUSDT.P">Blur (BLUR)</option>
            <option value="SUPERUSDT.P">Super (SUPER)</option>
            <option value="1000BONKUSDT.P">Bonk (1000BONK)</option>

          </select>

        </div>
        <button onClick={handleFullscreen} className="inputin2">
          Fullscreen
        </button>
      </div>
      <div className="tradingview-widget" style={{ borderRadius: "25px", overflow: "hidden" }}>
        <iframe
          ref={iframeRef}
          src={`https://s.tradingview.com/widgetembed/?symbol=BYBIT:${symbol}&interval=15&theme=Light&style=1&timezone=Etc%2FUTC&withdateranges=1&studies=%5B%22Moving%20Average%20Exponential%22%5D&studiesOverrides=%7B%22Moving%20Average%20Exponential.length%22%3A50%7D&overrides=%7B%22paneProperties.background%22%3A%20%22%23FFFFFF%22%2C%20%22paneProperties.vertGridProperties.color%22%3A%20%22%23FFFFFF%22%2C%20%22paneProperties.horzGridProperties.color%22%3A%20%22%23FFFFFF%22%2C%20%22mainSeriesProperties.candleStyle.upColor%22%3A%20%22%23FFFFFF%22%2C%20%22mainSeriesProperties.candleStyle.downColor%22%3A%20%22%23000000%22%2C%20%22mainSeriesProperties.candleStyle.borderUpColor%22%3A%20%22%23000000%22%2C%20%22mainSeriesProperties.candleStyle.borderDownColor%22%3A%20%22%23000000%22%2C%20%22mainSeriesProperties.candleStyle.wickUpColor%22%3A%20%22%23000000%22%2C%20%22mainSeriesProperties.candleStyle.wickDownColor%22%3A%20%22%23000000%22%7D`}
          width="100%"
          height="400px"
          frameBorder="0"
          allowTransparency="true"
          scrolling="no"
          allowFullScreen
        ></iframe>
      </div>
    </section>
  );
};

export default TradingViewWidget;
