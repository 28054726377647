import React, { useState, useRef, useEffect } from 'react';
import Cookies from 'js-cookie';
import "../style/Onboarding.css";
import apiPermissionsImg from '../../assets/image 28.png';
import ipExampleImg from '../../assets/image 29.png';
import video1 from '../../assets/video1.mp4'; // Import the video files
import video2 from '../../assets/video2.mp4';
import playIcon from '../../assets/play-icon.svg'; // Custom play button
import largeImage from '../../assets/large-image.png'; // The larger image

import Header from "../components/CheckBotStatus";
import CheckBotStatus from "../components/Header";

const formatTime = (seconds) => {
  const minutes = Math.floor(seconds / 60);
  const secs = Math.floor(seconds % 60);
  return `${minutes}:${secs < 10 ? '0' : ''}${secs}`;
};

const VideoPlayer = ({ videoSrc, title, onContinue }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [isDragging, setIsDragging] = useState(false); // For handling drag event
  const videoRef = useRef(null);
  const timelineRef = useRef(null); // For handling timeline

  const handlePlayPause = () => {
    if (isPlaying) {
      videoRef.current.pause();
    } else {
      videoRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  const handleTimeUpdate = () => {
    if (!isDragging) {
      setCurrentTime(videoRef.current.currentTime);
    }
  };

  const handleLoadedMetadata = () => {
    setDuration(videoRef.current.duration);
  };

  const handleProgressClick = (e) => {
    const rect = timelineRef.current.getBoundingClientRect();
    const clickX = e.clientX - rect.left;
    const newTime = (clickX / rect.width) * duration;
    videoRef.current.currentTime = newTime;
    setCurrentTime(newTime);
  };

  const handleDrag = (e) => {
    if (isDragging) {
      const rect = timelineRef.current.getBoundingClientRect();
      const dragX = e.clientX - rect.left;
      const newTime = Math.max(0, Math.min((dragX / rect.width) * duration, duration));
      setCurrentTime(newTime);
    }
  };

  const handleDragStart = () => {
    setIsDragging(true);
    videoRef.current.pause(); // Pause the video while dragging
  };

  const handleDragEnd = () => {
    setIsDragging(false);
    videoRef.current.currentTime = currentTime; // Update the video time after dragging
    if (isPlaying) {
      videoRef.current.play(); // Resume playing if it was already playing
    }
  };

  const [isFullscreen, setIsFullscreen] = useState(false); // Přidáno pro sledování stavu fullscreen

  useEffect(() => {
    const handleFullScreen = (e) => {
      if (e.key === 'f' || e.key === 'F') {
        e.preventDefault();
        if (!document.fullscreenElement) {
          if (videoRef.current.requestFullscreen) {
            videoRef.current.requestFullscreen();
          } else if (videoRef.current.webkitRequestFullscreen) { // For Safari
            videoRef.current.webkitRequestFullscreen();
          } else if (videoRef.current.msRequestFullscreen) { // For IE11
            videoRef.current.msRequestFullscreen();
          }
          setIsFullscreen(true);
        } else {
          if (document.exitFullscreen) {
            document.exitFullscreen();
          } else if (document.webkitExitFullscreen) { // For Safari
            document.webkitExitFullscreen();
          } else if (document.msExitFullscreen) { // For IE11
            document.msExitFullscreen();
          }
          setIsFullscreen(false);
        }
      }
    };

    document.addEventListener('keydown', handleFullScreen);

    return () => {
      document.removeEventListener('keydown', handleFullScreen);
    };
  }, []);

  useEffect(() => {
    if (isFullscreen) {
      videoRef.current.classList.add('fullscreen'); // Add fullscreen class when in fullscreen mode
    } else {
      videoRef.current.classList.remove('fullscreen'); // Remove fullscreen class when not in fullscreen mode
    }
  }, [isFullscreen]);

  return (
    <div className={`video-container ${isPlaying ? 'playing' : ''}`}>
      <h2>{title}</h2>
      <div className="custom-video">
        <video
          ref={videoRef}
          className="video-file"
          onClick={handlePlayPause}
          onTimeUpdate={handleTimeUpdate}
          onLoadedMetadata={handleLoadedMetadata}
          onEnded={() => setIsPlaying(false)}
        >
          <source src={videoSrc} type="video/mp4" />
          Tento prohlížeč toto video nepodporuje.
        </video>
        {!isPlaying && (
          <div className="play-button" onClick={handlePlayPause}>
            <img src={playIcon} alt="Play" />
          </div>
        )}
      </div>

      {/* Timeline and current/remaining time display */}
      <div
        className="video-timeline"
        ref={timelineRef}
        onMouseDown={handleDragStart}
        onMouseMove={handleDrag}
        onMouseUp={handleDragEnd}
        onMouseLeave={handleDragEnd} // Stop dragging when the mouse leaves the timeline
        onClick={handleProgressClick}
      >
        <div
          className="progress"
          style={{ width: `${(currentTime / duration) * 100}%`, transition: isDragging ? 'none' : 'width 0.1s linear' }}
        ></div>
        <div
          className="progress2"
          style={{ width: `${(currentTime / duration) * 100}%`, transition: isDragging ? 'none' : 'width 0.1s linear' }}
        ></div>
      </div>
      <div className="time-info">
        <span>{formatTime(currentTime)}</span>
        <span>{formatTime(duration - currentTime)}</span>
      </div>

      {/* Large image below the video */}
      <div className="large-image-container">
        <img src={largeImage} alt="Large" className="large-image" />
      </div>

      <button className="continue-btn" onClick={onContinue}>
        Continue
      </button>
    </div>
  );
};

const Onboarding = ({ boardingCloseFunc }) => {
  const [currentStep, setCurrentStep] = useState(1);
  const [apiKey, setApiKey] = useState('');
  const [apiSecret, setApiSecret] = useState('');
  const [isLoading, setIsLoading] = useState(false); // Loading state
  const [loadingStatus] = useState('notdone');
  const [isApiSubmitted, setIsApiSubmitted] = useState(false);
  const [notification, setNotification] = useState(null);

  useEffect(() => {
    const authToken = Cookies.get('auth_token');
    if (authToken) {
      checkStatus(authToken);
    } else {
      showNotification('No active session found, please login.');
    }
  }, []);

  const checkStatus = (authToken) => {
    fetch('https://app.byxbot.com/php/status.php', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: new URLSearchParams({
        auth_token: authToken,
        action: 'check_status',
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === 'waiting') {
          setCurrentStep(4);
        } else if (data.status === 'done') {
          setCurrentStep(5);
        } else if (data.status === 'up') {
          boardingCloseFunc()
        } else {
          setCurrentStep(1);
        }
      });
  };

  const handleStepChange = (step) => {
    setIsLoading(true);
    setCurrentStep(step); // Krok se změní okamžitě
    setTimeout(() => {
      setIsLoading(false); // Skryje loader po 500 ms
    }, 1000);  // Krátké zpoždění pro vizuální efekt loaderu
  };

  const handleNextStep = () => {
    setCurrentStep((prevStep) => prevStep + 1);

    setIsLoading(true); // Show the loader
    setTimeout(() => {
      setIsLoading(false); // Hide the loader after simulated loading time
    }, 1500); // Simulated loading time
  };

  const handleApiKeyChange = (e) => {
    setApiKey(e.target.value);
  };

  const handleApiSecretChange = (e) => {
    setApiSecret(e.target.value);
  };

  const handleCopyIpAddress = () => {
    navigator.clipboard.writeText('89.203.250.21');
    showNotification('IP address copied!');
  };

  const handleSubmit = () => {
    if (!apiKey || !apiSecret) {
      showNotification('Fields must be filled!');
      return;
    }

    setIsLoading(true); // Show the loader

    const authToken = Cookies.get('auth_token');

    // Check if authToken exists and log its value
    if (!authToken) {
      console.error('Auth token is missing. Please log in again.');
      showNotification('No active session found, please login.');
      setIsLoading(false); // Hide the loader
      return;
    }

    console.log('Auth Token:', authToken); // Debugging statement

    fetch('https://app.byxbot.com/php/status.php', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: new URLSearchParams({
        auth_token: authToken,
        action: 'submit_api',
        api_key: apiKey,
        api_secret: apiSecret,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        setIsLoading(false); // Hide the loader
        if (data.success) {
          setIsApiSubmitted(true);
          setCurrentStep(4);
          showNotification('Form successfully sent!');

          // Save to cookies that the user has reached step 4
          Cookies.set('onboarding_step', '4', { expires: 7 });

          // Send a message to Discord webhook
          const userEmail = Cookies.get('email');
          console.log('User Email:', userEmail); // Debugging statement

          fetch('https://discord.com/api/webhooks/1284670440245301301/-PRwLNC1uv6bHDCjcbrSOiE1A7LT70k4XYQXWcLWQjs8utK7gccEV9x2kpG4XvzoPQOm', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
              embeds: [
                {
                  title: 'New request waiting for approval!',
                  description: 'User send API form.',
                  color: 0xcbbff7,
                  fields: [
                    {
                      name: 'Email uživatele',
                      value: userEmail ? userEmail : 'Not Avaible',
                      inline: true,
                    },
                    {
                      name: 'Link for user in the database',
                      value: `[Click here](https://app.byxbot.com/phpmyadmin/sql.php?db=byx&table=users&pos=0&where=email='${userEmail}')`,
                      inline: false,
                    },
                  ],
                  footer: {
                    text: 'ByXBot',
                  },
                  timestamp: new Date().toISOString(),
                },
              ],
            }),
          })
            .then((response) => response.json())
            .then((data) => console.log('Discord webhook send:', data))
            .catch((error) => console.error('Error in creating new webhook:', error));
        } else {
          showNotification('Error in sending a new request');
          console.error('Submission error:', data); // Debugging statement
        }
      })
      .catch((error) => {
        setIsLoading(false); // Hide the loader
        console.error('Fetch error:', error); // Log fetch errors
        showNotification('Error in communication with the server');
      });
  };

  useEffect(() => {
    if (loadingStatus === 'notdone' && currentStep === 4) {
      const authToken = Cookies.get('auth_token');
      checkStatus(authToken);
    }
  }, [loadingStatus, currentStep]);

  const showNotification = (message) => {
    setNotification(message);
    setTimeout(() => {
      setNotification(null);
    }, 3000);
  };

  const updateStatus = () => {
    fetch('https://app.byxbot.com/php/status.php', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: new URLSearchParams({
        auth_token: Cookies.get('auth_token'),
        action: 'status_up',
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data.message);
      });
  }

  return (
    <>
    <Header />
    <CheckBotStatus />

    <div className="onboarding-container">
      {notification && <div className="notification">{notification}</div>}

      {/* Loader */}
      {isLoading && (
        <div className="loader-container2 show fade-in">
          <div className="loader2"></div>
        </div>
      )}

      {currentStep !== 5 && currentStep !== 4 && (
        <nav className="step-nav">
          <button
            className={`step-btn ${currentStep >= 1 ? 'active' : ''} ${currentStep > 1 ? 'completed' : ''}`}
            onClick={() => handleStepChange(1)}
            disabled={isApiSubmitted || currentStep >= 4}
            style={{ cursor: isApiSubmitted || currentStep >= 4 ? 'not-allowed' : 'pointer' }}
          >
            Where to get API
          </button>
          <button
            className={`step-btn ${currentStep >= 2 ? 'active' : ''} ${currentStep > 2 ? 'completed' : ''}`}
            onClick={() => handleStepChange(2)}
            disabled={currentStep < 2 || isApiSubmitted || currentStep >= 4}
            style={{ cursor: currentStep < 2 || isApiSubmitted || currentStep >= 4 ? 'not-allowed' : 'pointer' }}
          >
            How everything works
          </button>
          <button
            className={`step-btn ${currentStep >= 3 ? 'active' : ''} ${currentStep > 3 ? 'completed' : ''}`}
            onClick={() => handleStepChange(3)}
            disabled={currentStep < 3 || isApiSubmitted || currentStep >= 4}
            style={{ cursor: currentStep < 3 || isApiSubmitted || currentStep >= 4 ? 'not-allowed' : 'pointer' }}
          >
            Application Form
          </button>
        </nav>
      )}

      <div className="step-content">
        {currentStep === 1 && (
          <>
            <VideoPlayer videoSrc={video1} title="Video tutorial #1 - Where to get API?" onContinue={handleNextStep} />
            <p className="fullscreen-info">For fullscren press F (Also for window)</p>
          </>
        )}

        {currentStep === 2 && (
          <>
            <VideoPlayer videoSrc={video2} title="Video tutorial #2 - How everything works?" onContinue={handleNextStep} />
            <p className="fullscreen-info">For fullscren press F (Also for window)</p>
          </>
        )}

        {currentStep === 3 && (
          <div className="form-step">
            <h2>Fill your form here.</h2>
            <div className="instructions">
              <div className="instruction-step">
                <p className='pform'>Fill the form exactly like shown in the picture.</p>
                <img src={ipExampleImg} alt="IP Example" className="instruction-img" />
                <div className="ip-address">
                  <input className="onboradingin2" type="text" value="89.203.250.21" readOnly />
                  <button onClick={handleCopyIpAddress}>Copy</button>
                </div>
              </div>
            </div>
            <div className="instruction-step">
            <p className='pform'> 1. Fill information </p>
            <div className="input-group">
              <label htmlFor="api-key">API Key:</label>
              <input
                className="onboradingin"
                id="api-key"
                type="text"
                value={apiKey}
                onChange={handleApiKeyChange}
              />
            </div>

            <div className="input-group">
              <label htmlFor="api-secret">API Secret:</label>
              <input
                className="onboradingin"
                id="api-secret"
                type="text"
                value={apiSecret}
                onChange={handleApiSecretChange}
              />
            </div>
            </div>
            <button className="continue-btn" onClick={handleSubmit}>
              Send request
            </button>

            <div className="large-image-container2">
            </div>
          </div>
        )}

        {currentStep === 4 && (
          <div className="step-4-content">
            <div className="login-background"></div>
            <h2>Your request is being processed...</h2>
            <p className='finalp'>Please wait for request to get accept. It can take up to 24h</p>
            <p className='fullscreen-info2'>If you have any questions contact us at byx.business@gmail.com</p>

            {/* Loader animation */}
            <div className="zzz-loader">
          <span></span>
          <span></span>
          <span></span>
        </div>

          </div>
        )}

        {currentStep === 5 && (
          <div className="final-step">
            <div className="login-background"></div>
            <h2>All Done!</h2>
            <p className='finalp'>You request has been accepted. The automatization process will begin on your accont.</p>
            <p className='fullscreen-info2'>If you have any questions contact us at byx.business@gmail.com</p>
            <button className="continue-btn" onClick={() => {
              updateStatus();
              boardingCloseFunc();
            }}>
              Go to App
            </button>
          </div>
        )}
      </div>
    </div>
      </>
  );
};

export default Onboarding;
