import React from "react";

export default function Filter({
  coins, 
  searchQuery, setSearchQuery,
  filterStatus, setFilterStatus,
  filterProfitLoss, setFilterProfitLoss,
  filterTradeType, setFilterTradeType
}) {
  return (
    <div id="sidebar">
      <select
        className="inputin"
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
      >
        <option value="">Pick a coin</option>
        {coins && coins.map(coin => (
          <option key={coin} value={coin} className="coin-option">{coin}</option>
        ))}
      </select>

      <select className="inputin"
        value={filterStatus}
        onChange={(e) => {
          setFilterStatus(e.target.value);
          if (e.target.value === "opened") {
            setFilterProfitLoss("all");
          }
        }}
      >
        <option className="optionin" value="all">All</option>
        <option value="opened">Opened</option>
        <option value="closed">Closed</option>
      </select>

      <select className="inputin"
        value={filterProfitLoss}
        onChange={(e) => setFilterProfitLoss(e.target.value)}
        disabled={filterStatus === "opened"}
      >
        <option value="all">All</option>
        <option value="profit">Profit</option>
        <option value="loss">Loss</option>
      </select>

      {/* New select for trade type */}
      <select className="inputin"
        value={filterTradeType}
        onChange={(e) => setFilterTradeType(e.target.value)}
        disabled={filterStatus === "opened"}
      >
        <option value="all">All</option>
        <option value="short">Short</option>
        <option value="long">Long</option>
      </select>
    </div>
  );
}
